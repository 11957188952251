import { useContext, useEffect, useState } from "react";
import ContentPrincipal from "../Content";
import axios from "axios";
import BaseUrl from "../BaseUrl";
import Swal from "sweetalert2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../auth/Usercheck";
import TransactionExportPdf from "./pdf/TransactonPdf";
import ExportExcel from "../ExcelExport";
import UserExportPdf from "./pdf/UtilisateurPdf";
import can from "../auth/CanUser";
import DataTable from "react-data-table-component";
import CustomerStyle from "../customerStyle";

 

const url = BaseUrl();
const customStyles = CustomerStyle();
function MarchandsPage()
{
    const {user, setUsers} = useContext(UserContext);
    const navigate = useNavigate();

    const [errors,setErrors] = useState({});
    const [loadingform,setLoadingform] = useState(false);
    const [loading, setLoading] = useState();
    const [marchands,setMarchands] = useState([]);
    const [marchand,setMarchand] = useState(null);
    const [marchandSee,setMarchandSee] = useState([]);
    const [error,setError] = useState();
    const [countrieList,setCountrieList] = useState([]);
    const [itemsList,setItemsList] = useState([]);
    const [itemsListFilter,setItemsListFilter] = useState([]);


    const [currentPage,setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records =marchands?.length >0 ? marchands.slice(firstIndex, lastIndex) : [];
    const npage =marchands?.length >0 ? Math.ceil(marchands.length / recordsPerPage):0;
    const numbers = [...Array(npage + 1).keys()].slice(1);

    const [seeCheck_Pass,setSeeCheck_Pass] = useState(false);


    const [marchanData,setMarchanData] = useState({
        "raison_social":"",
        "adresse":"",
        "email":"",
        "secteur_activite":"",
        "num_register":"",
        "apikey_imoney":"",
        "siteId_imoney":"",
        "secretKey_imoney":"",
        "countrie_id":"",
        "tel":"",
        "pourcent":"",
        "check_passe":"",
        "callback_token":"",
    });
    const [marchanDataEdit,setMarchanDataEdit] = useState({
        "raison_social":"",
        "adresse":"",
        "email":"",
        "secteur_activite":"",
        "num_register":"",
        "apikey_imoney":"",
        "siteId_imoney":"",
        "secretKey_imoney":"",
        "countrie_id":"",
        "tel":"",
        "pourcent":"",
        "check_passe":"",
        "callback_token":"",
    });

    const columns = [
        {
            name: 'DATE',
            selector: row => `${moment(row.created_at).format("Do MMMM YYYY")}`,
            sortable: true,
        },
        {
            name: 'RAISON SOCIALE',
            selector: row => row?.raison_social,
            sortable: true,
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
            sortable: true,
          },
        {
          name: 'TELEPHONE',
          selector: row => row.tel,
          sortable: true,
        },

        {
            name: 'POURCENTAGE',
            selector: row => row.pourcent,
            sortable: true,
          },
          {
            name: 'PAYS',
            selector: row => row.countrie?.libelle,
            sortable: true,
          },
          {
            name: "STATUT",
            selector : row => (
              <>
               {row.is_active == 1 && (
              <span className="badge bg-success">Actif</span>
              )}
              {row.is_active == 0 && (
                  <span className="badge bg-danger">Inactif</span>
              )}
              </>
            )
          },
    
        {
            name: "Action",
            selector : row => (
                <>
                    <button className="btn btn-secondary btn-sm mx-1" onClick={()=>openModalView(row)}><i class="ri-eye-line"></i></button>
                    <button className="btn btn-primary btn-sm" onClick={()=>openModalEdit(row)}><i class="ri-ball-pen-fill"></i></button>
                    <button className={row.is_active == 1 ? "btn btn-danger btn-sm mx-1" : "btn btn-success btn-sm mx-1"} onClick={()=>deleteMarchand(row.id)} ><i class="ri-play-fill"></i></button>
                </>
            )
          },

    ];

    // useEffect(()=>{
    //     if(localStorage.getItem('_token_') == null){
         
    //       localStorage.removeItem('_token_');
    //       navigate('/');
    
    //   }
    //   },[user]);

    useEffect(()=>{
        if(!can(user,'voir-page-marchand')){
            navigate('/');
        }
        fetchDataMarchands();
        fetchCountrieList();
        // if(user && user?.rUser !== 1)
        // {
        //     navigate('/dashboard')
        // }
    },[user]);

    const fetchDataMarchands=async()=>{
        setLoading(true);
        try {
            axios.get(url+'/all/marchands',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                setLoading(false);
            
                setMarchands(resp.data.datas);
                setItemsList(resp.data.datas);
                setItemsListFilter(resp.data.datas);
                
            })
        } catch (error) {
            console.log(error);
        }
    }

    const fetchCountrieList = async ()=>{
        try {
            axios.get(url+'/countrie/list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{

                if(resp.status == 200)
                {
                    setCountrieList(resp.data.datas);
                }
                else
                {
                    console.log(resp.data.status);   
                }
            })
        } catch (error) {
            
        }
    }


    const handleChange = (e)=>{
        setMarchanData({
            ...marchanData,
            [e.target.name]:e.target.value
        });
    }

    const handleChangeEdit = (e)=>{
        setMarchanDataEdit({
            ...marchanDataEdit,
            [e.target.name]:e.target.value
        });
    }
    const submitForm= (e)=>{
        e.preventDefault();
  
        const _formData = new FormData();
        _formData.append('raison_social',marchanData.raison_social);
        _formData.append('adresse',marchanData.adresse);
        _formData.append('email',marchanData.email);
        _formData.append('secteur_activite',marchanData.secteur_activite);
        _formData.append('num_register',marchanData.num_register);
        _formData.append('apikey_imoney',marchanData.apikey_imoney);
        _formData.append('siteId_imoney',marchanData.siteId_imoney);
        _formData.append('secretKey_imoney',marchanData.secretKey_imoney);
        _formData.append('countrie_id',marchanData.countrie_id);
        _formData.append('tel',marchanData.tel);
        _formData.append('pourcent',marchanData.pourcent);
        _formData.append('check_passe',marchanData.check_passe);
        _formData.append('callback_token',marchanData.callback_token);


        setLoadingform(true);
        try {
             axios.post(url+'/store/marchand',_formData,
            {
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
                credentials:'include'
            }
            ).then((resp)=>{
                // console.log('ici');
                setLoadingform(false);
                if(resp.status == 200)
                {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: resp.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                      fetchDataMarchands();
                      setMarchanData({
                        "raison_social":"",
                        "adresse":"",
                        "email":"",
                        "secteur_activite":"",
                        "num_register":"",
                        "apikey_imoney":"",
                        "siteId_imoney":"",
                        "secretKey_imoney":"",
                        "countrie_id":"",
                        "tel":"",
                        "pourcent":"",
                        "callback_token":"",
                    })
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title:  resp.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                }
                window.$('#basicModal').modal('hide');
            }).catch((error)=>{
                setLoadingform(false);
                setError(error.response.data.message);
                setErrors(error.response.data.error);
            })
        } catch (error) {
            console.log(error);
        } 




    }

    const openModaladd =()=>{
        setMarchand(null);
        setError('');
        window.$('#basicModal').modal('show');
    }

    const openModalView =(marchand)=>{
        setMarchandSee(marchand);
        window.$('#basicModalsee').modal('show');
    }

    const openModalEdit = (marchand)=>{

        setMarchand(marchand);
        // console.log(marchand)
        setMarchanDataEdit({
            "raison_social":marchand.raison_social,
            "adresse":marchand.adresse !=null ? marchand.adresse : "",
            "email":marchand.email !=null ? marchand.email :"",
            "secteur_activite":marchand.secteur_activite !=null ? marchand.secteur_activite :"",
            "num_register":marchand.num_register !=null ? marchand.num_register : "",
            "apikey_imoney":marchand.apikey_imoney !=null ? marchand.apikey_imoney : "",
            "siteId_imoney":marchand.siteId_imoney !=null ? marchand.siteId_imoney : "",
            "secretKey_imoney":marchand.secretKey_imoney !=null ? marchand.secretKey_imoney : "",
            "countrie_id":marchand.countrie_id !=null ? marchand.countrie_id : "",
            "pourcent":marchand.pourcent !=null ? marchand.pourcent : "",
            "tel":marchand.tel !=null ? marchand.tel : "",
            "check_passe":marchand.check_passe !=null ? marchand.check_passe : "",
            "callback_token":marchand.callback_token !=null ? marchand.callback_token : "",
        })

        window.$('#basicModaledit').modal('show');
    }


    const submitFormEdit = (e)=>{
        e.preventDefault();

        const _formData = new FormData();
        _formData.append('raison_social',marchanDataEdit.raison_social !==undefined ? marchanDataEdit.raison_social : "");
        _formData.append('adresse',marchanDataEdit.adresse !==undefined ? marchanDataEdit.adresse : "");
        _formData.append('email',marchanDataEdit.email !==undefined ? marchanDataEdit.email : "");
        _formData.append('secteur_activite',marchanDataEdit.secteur_activite !==undefined ? marchanDataEdit.secteur_activite : "");
        _formData.append('num_register',marchanDataEdit.num_register !==undefined ? marchanDataEdit.num_register : "");
        _formData.append('apikey_imoney',marchanDataEdit.apikey_imoney !==undefined ? marchanDataEdit.apikey_imoney : "");
        _formData.append('siteId_imoney',marchanDataEdit.siteId_imoney !==undefined ? marchanDataEdit.siteId_imoney : "");
        _formData.append('secretKey_imoney',marchanDataEdit.secretKey_imoney !==undefined ? marchanDataEdit.secretKey_imoney : "");
        _formData.append('countrie_id',marchanDataEdit.countrie_id);
        _formData.append('tel',marchanDataEdit.tel !==undefined ? marchanDataEdit.tel : "");
        _formData.append('pourcent',marchanDataEdit.pourcent);
        _formData.append('check_passe',marchanDataEdit.check_passe !==undefined ? marchanDataEdit.check_passe : "");
        _formData.append('callback_token',marchanDataEdit.callback_token !==undefined ? marchanDataEdit.callback_token : "");
        _formData.append('id',marchand.id);

        console.log(_formData);

        try {
            axios.post(url+'/update/marchand',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
            setErrors({})
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
                     fetchDataMarchands();
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               window.$('#basicModaledit').modal('hide');
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);
           })
       } catch (error) {
           console.log(error);
       } 
    
    }

    function prePage()
    {
        if(currentPage !== 1)
        {
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage()
    {
        if(currentPage !== npage)
        {
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(n)
    {
       setCurrentPage(n);
    }

    const deleteMarchand=(id)=>{
        Swal.fire({
            title: 'ATTENTION !',
            text: 'Êtes-vous sûre de vouloir changer le statut.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText:"NON",
            confirmButtonText: 'OUI',
            cancelButtonColor:"red"
          }).then((result) => {
            if (result.isConfirmed) {
                try {
                    axios.get(url+'/delete/marchand/?id='+id,
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                        }
                    ).then((resp)=>{
                        if(resp.status == 200)
                        {
                            Swal.fire(
                                'Supprimé',
                                resp.data.message,
                                'success'
                              )
                        }else{
                            Swal.fire(
                                'Attention',
                                resp.data.message,
                                'error'
                              )
                        }
                        fetchDataMarchands();
                    })
                } catch (error) {
                    console.log(error);
                }

            
            }
          }); 
    }

    const searchDataTrans=async(e)=>{
        var search = e.target.value;
   
            try {
                await  axios.post(url+'/search/marchands',{search:search},
                  {
                      headers:{
                          'Content-Type':'application/json',
                          "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                          
                      },
                      // credentials:'include'
                  }
                  ).then((resp)=>{
                    //   setLoadingData(true);
                      if(resp.status == 200)
                      {
                        setMarchands(resp.data.data);
                      }
                  })
              } catch (error) {
                  console.log(error);
              }
       
    }

    const handleFilter=(event)=>{
        const datas = itemsListFilter.filter(row => row.raison_social.toLowerCase().includes(event.target.value.toLowerCase()));
        setItemsList(datas);
    }

    // console.log(itemsList);

    return (
        <>
            <ContentPrincipal navactive={"marchands"} ulShownav={""}>
                    <div class="pagetitle mb-3">
                            <h1>Marchands {can(user,"ajouter-marchand") && <button type="button" class="btn btn-primary btn-sm float-end" onClick={openModaladd}>Ajouter un marchand</button>} </h1>
                            
                    </div>

                    <section class="section">
                    <div class="row">
                        <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">Liste des Marchands
                            {/* <ExportExcel excelData={marchands} filename={'Export utilisateurs Xls'}/>
                            <UserExportPdf pdfData={marchands} /> */}
                            </h5>
                    <div class="card-header row g-4">
                        <div class="col-md-2">
                           
                        </div>
                        <div class="col-md-4">
                            
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <input type="search" placeholder="Rechercher..." class="form-control" name="search" id="search" onChange={handleFilter}/>
                            </div>
                        </div>
                    </div>

                  
                            <DataTable 
                                    columns={columns} 
                                    data={itemsList} 
                                    customStyles={customStyles}
                                    pagination
                                    selectableRows
                                    progressPending={loading}
                                    progressComponent={
                                        <>
                                        <div class="text-center">
                                            <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        </>
                                    } 
                                />
                    
                    {/* <table class="table datatable">
                        <thead>
                            <tr>
                                <th>
                                    Date de creation
                                </th>
                                <th>Raison sociale</th>
                                <th>Email</th>
                                <th >Contact</th>
                                <th>Registre commerce</th>
                                <th>Pourcentage deduit (%)</th>
                                <th>Pays</th>
                                <th>Statut</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && 
                                <tr className="text-center">
                                    
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                
                                </tr>
                            }
                            {!loading &&
                                records?.map((marchand,index)=>
                                <tr>
                                    <td>{moment(marchand.created_at).format("Do MMMM YYYY")}</td>
                                    <td>{marchand.raison_social}</td>
                                    <td>{marchand.email}</td>
                                    <td>{marchand.tel}</td>
                                    <td className="text-center">{marchand.num_register}</td>
                                    <td className="text-center">{marchand.pourcent}</td>
                                    <td >{marchand.countrie?.libelle}</td>
                                    <td>
                                        {marchand.is_active == 1 && <span class="badge bg-success">active</span>}
                                        {marchand.is_active == 0 && <span class="badge bg-danger">desactive</span>}
                                    </td>
                                    <td>
                                        <button className="btn btn-secondary btn-sm mx-1" onClick={()=>openModalView(marchand)}><i class="ri-eye-line"></i></button>
                                        <button className="btn btn-primary btn-sm" onClick={()=>openModalEdit(marchand)}><i class="ri-ball-pen-fill"></i></button>
                                        <button className={marchand.is_active == 1 ? "btn btn-danger btn-sm mx-1" : "btn btn-success btn-sm mx-1"} onClick={()=>deleteMarchand(marchand.id)} ><i class="ri-play-fill"></i></button>
                                       
                                        
                                    </td>
                                </tr>
                                )
                            }
                            
                        </tbody>
                </table>
                    <nav aria-label="Page navigation example" className="float-end">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link"   onClick={prePage}>
                                <span aria-hidden="true">«</span>
                                </a>
                            </li>
                            {numbers.map((n,i)=>
                                <li class={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                    <a class="page-link"  onClick={()=>changePage(n)}>{n}</a>
                                </li>
                            )}
                            

                            <li class="page-item">
                                <a class="page-link" onClick={nextPage}>
                                <span aria-hidden="true">»</span>
                                </a>
                            </li>
                        </ul>
                    </nav> */}

                            </div>
                        </div>

                        </div>
                    </div>
                    </section>

                    <div class="modal fade" id="basicModal" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Enregistrer un marchand {error && <span className="text-danger">{error}</span>}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={submitForm }> 
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Raison sociale<span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="raison_social" onChange={handleChange} value={marchanData.raison_social}/>
                                        {errors && errors.raison_social && (
                                            <span className="text-danger">{errors.raison_social[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Adresse e-mail </label>
                                        <input type="email" class="form-control" id="inputName5" name="email" onChange={handleChange} value={marchanData.email}/>
                                        {errors && errors.email && (
                                            <span className="text-danger">{errors.email[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Asresse </label>
                                        <input type="text" class="form-control"  name="adresse" onChange={handleChange} value={marchanData.adresse}/>
                                        {errors && errors.adresse && (
                                            <span className="text-danger">{errors.adresse[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Téléphone </label>
                                        <input type="text" class="form-control"  name="tel" onChange={handleChange} value={marchanData.tel}/>
                                        {errors && errors.tel && (
                                            <span className="text-danger">{errors.tel[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                            <label for="inputState" class="form-label">Pays <span className="text-danger">*</span></label>
                                            <select id="inputState" class="form-select" name="countrie_id" onChange={handleChange} value={marchanData.countrie_id}>
                                                <option value="">...</option>
                                                {countrieList?.map((countrie,index)=>
                                                        <option value={countrie.id}>{countrie.libelle}</option>
                                                    )}
                                            </select>
                                        {errors && errors.countrie_id && (
                                            <span className="text-danger">{errors.countrie_id[0]}</span>
                                        )}
                                    </div>
                                    <hr />
                                    <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Pass Api V2 {!seeCheck_Pass && <i class="ri-eye-line p-2 border" onClick={()=>setSeeCheck_Pass(true)}></i>} {seeCheck_Pass && <i class="ri-eye-off-line p-2 border"  onClick={()=>setSeeCheck_Pass(false)}></i>}</label>
                                            <input type={!seeCheck_Pass ? "password" : "text"} class="form-control"  name="check_passe"  onChange={handleChange} value={marchanData.check_passe}/>
                                        </div> 
                                    <div class="col-6">
                                        <label for="inputAddress2" class="form-label">Callback Token  </label>
                                        <input type="text" class="form-control"  name="callback_token"  onChange={handleChange} value={marchanData.callback_token}/>
                                    </div> 

                                    <div class="col-6">
                                        <label for="inputAddress5" class="form-label">N° Registre de commerce </label>
                                        <input type="text" class="form-control" id="inputAddres5s" name="num_register"  onChange={handleChange} value={marchanData.num_register}/>
                                        {errors && errors.num_register && (
                                            <span className="text-danger">{errors.num_register[0]}</span>
                                        )}
                                    </div>

                                    <div class="col-6">
                                        <label for="inputAddress5" class="form-label">Pourcentage (%)</label>
                                        <input type="text" class="form-control" id="inputAddres5s" name="pourcent"  onChange={handleChange} value={marchanData.pourcent} placeholder="Ex: 2.50"/>
                                        {errors && errors.pourcent && (
                                            <span className="text-danger">{errors.pourcent[0]}</span>
                                        )}
                                    </div>
                                        {/* <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Address</label>
                                            <input type="text" class="form-control"  name="adresse"  onChange={handleChange} value={marchanData.adresse}/>
                                        </div>*/}
                                        
                                        {/* <div class="col-md-6">
                                            <label for="inputCity" class="form-label">Email professionnel</label>
                                            <input type="text" class="form-control"  name="professionel_mail" onChange={handleChange} value={marchanData.professionel_mail}/>

                                        </div> */}
                                        <div class="col-md-6">
                                            <label for="secteur_activite" class="form-label">Secteur Activité</label>
                                            <input type="text" class="form-control"  name="secteur_activite" onChange={handleChange} value={marchanData.secteur_activite}/>

                                        </div>
                                        {/* <div class="col-md-4">
                                            <label for="inputState" class="form-label">Secteur d'activité</label>
                                            <select id="inputState" class="form-select" name="secteur" onChange={handleChange} value={marchanData.secteur}>
                                                <option>Choose...</option>
                                                <option>...</option>
                                            </select>
                                        </div> */}

                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">Secret Key iMONEY  <span className="text-danger">*</span></label>
                                            <input type="text" class="form-control"  name="secretKey_imoney"  onChange={handleChange} value={marchanData.secretKey_imoney}/>

                                        </div>
                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">APIKEY IMONEY <span className="text-danger">*</span></label>
                                            <input type="text" class="form-control"  name="apikey_imoney" onChange={handleChange} value={marchanData.apikey_imoney}/>

                                        </div>
                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">SITE ID IMONEY <span className="text-danger">*</span></label>
                                            <input type="text" class="form-control"  name="siteId_imoney" onChange={handleChange} value={marchanData.siteId_imoney}/>
                                        </div>
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-primary">Valider</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
                    </div>

                    

                     <div class="modal fade" id="basicModalsee" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header bg-info">
                            <h5 class="modal-title">Detail marchand</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row g-1" > 
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Raison sociale</label>
                                        {/* <input type="text" class="form-control" id="inputName5" name="nom"  value={marchandSee?.raison_social} disabled/> */}
                                       <span className="fw-bold card p-0">{marchandSee?.raison_social}</span>
                                    </div>
                                    {/* <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Prenoms </label>
                                        <input type="text" class="form-control" id="inputName5" name="prenom"  value={marchand.prenom} disabled/>
                                   
                                    </div> */}
                                    <div class="col-md-6">
                                        <label for="inputEmail5" class="form-label">Email </label>
                                        <span className="fw-bold card p-0">{marchandSee?.email}</span>
                                        {/* <input type="email" class="form-control"  name="email"  value={marchandSee?.email ? marchandSee.email : ""} disabled/> */}
                                      
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Téléphone </label>
                                        <span className="fw-bold card p-0">{marchandSee?.tel}</span>
                                        {/* <input type="text" class="form-control"  name="tel"  value={marchandSee?.tel ? marchandSee?.tel : ""} disabled/> */}
                                        
                                    </div>
                                    <div class="col-md-4">
                                            <label for="inputState" class="form-label">Pays </label>
                                            <select id="inputState" class="form-select" name="countrie_id" value={marchandSee?.countrie_id} disabled>
                                                <option value="">...</option>
                                                {countrieList?.map((countrie,index)=>
                                                        <option value={countrie.id}>{countrie.libelle}</option>
                                                    )}
                                            </select>
                                       
                                    </div>
                                    <hr />
                                    <div class="col-6">
                                        <label for="inputAddress5" class="form-label">N° Registre de commerce </label>
                                        <span className="fw-bold card p-0">{marchandSee?.num_registre}</span>
                                        {/* <input type="text" class="form-control" id="inputAddres5s" name="num_registre"  value={marchandSee?.num_registre ? marchandSee?.num_registre : ""} disabled/> */}
                                       
                                    </div>
                                    {/* <div class="col-6">
                                        <label for="inputAddress5" class="form-label">Solde disponible <b>(F CFA)</b></label>
                                        <span className="fw-bold card p-0">{marchandSee?.solde}</span> */}
                                        {/* <input type="text" class="form-control text-danger fw-bold" id="inputAddres5s" name="solde"  value={marchandSee?.solde ? marchandSee?.solde : 0} disabled/> */}
                                       
                                    {/* </div> */}
                                        <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Address</label>
                                            <span className="fw-bold card p-0">{marchandSee?.adresse}</span>
                                            {/* <input type="text" class="form-control"  name="adresse"  value={marchandSee?.adresse} disabled/> */}
                                        </div>
                                        {/* <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Pass Api V2 </label>
                                            <span className="fw-bold card p-0">{marchandSee?.check_passe}</span>
                                        </div> */}
                                        <hr />
                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">SITE ID IMONEY</label>
                                            <span className="fw-bold card p-0">{marchandSee?.siteId_imoney}</span>
                                            {/* <input type="text" class="form-control"  name="siteId_imoney"  value={marchandSee?.siteId_imoney} disabled/> */}
                                        </div>
                           

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">Secret Key iMONEY </label>
                                            <span className="fw-bold card p-0">{marchandSee?.secretKey_imoney}</span>
                                            {/* <input type="text" class="form-control"  name="secretKey_imoney"  value={marchandSee?.secretKey_imoney} disabled/> */}

                                        </div>
                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">APIKEY IMONEY</label>
                                            <span className="fw-bold card p-0">{marchandSee?.apikey_imoney}</span>
                                            {/* <input type="text" class="form-control"  name="apikey_imoney" value={marchandSee?.apikey_imoney} disabled/> */}

                                        </div>

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">SITE ID DISTRIPAY</label>
                                            <span className="fw-bold card p-0">{marchandSee?.siteID}</span>
                                            {/* <input type="text" class="form-control"  name="siteId_imoney"  value={marchandSee?.siteID} disabled/> */}
                                        </div>

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">Secret Key DISTRIPAY</label>
                                            <span className="fw-bold card p-0">{marchandSee?.secretKey}</span>
                                            {/* <input type="text" class="form-control"  name="siteId_imoney"  value={marchandSee?.secretKey} disabled/> */}
                                        </div>

                                        <div class="col-md-4">
                                            <label for="inputCity" class="form-label">APIKEY DISTRIPAY</label>
                                            <span className="fw-bold card p-0">{marchandSee?.apiKey}</span>
                                            {/* <input type="text" class="form-control"  name="siteId_imoney"  value={marchandSee?.apiKey} disabled/> */}
                                        </div>

                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                       
                                        
                                        </div>
                                </div>
                            </div>
                           
                        </div>
                        </div>
                    </div> 

                    {/* <div class="modal fade" id="basicModaledit" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Modifier un marchand</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={submitFormEdit}> 
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Nom du marchand <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="nom" onChange={handleChange} value={marchanData.nom}/>
                                        {errors && errors.nom && (
                                            <span className="text-danger">{errors.nom[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Prenoms <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputName5" name="prenom" onChange={handleChange} value={marchanData.prenom}/>
                                        {errors && errors.prenom && (
                                            <span className="text-danger">{errors.prenom[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Email <span className="text-danger">*</span></label>
                                        <input type="email" class="form-control"  name="email" onChange={handleChange} value={marchanData.email}/>
                                        {errors && errors.email && (
                                            <span className="text-danger">{errors.email[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Téléphone <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control"  name="tel" onChange={handleChange} value={marchanData.tel}/>
                                        {errors && errors.tel && (
                                            <span className="text-danger">{errors.tel[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                            <label for="inputState" class="form-label">Pays <span className="text-danger">*</span></label>
                                            <select id="inputState" class="form-select" name="countrie_id" onChange={handleChange} value={marchanData.countrie_id}>
                                                <option value="">...</option>
                                                {countrieList?.map((countrie,index)=>
                                                    <option value={countrie.id}>{countrie.libelle}</option>
                                                )}
                                            </select>
                                        {errors && errors.countrie_id && (
                                            <span className="text-danger">{errors.countrie_id[0]}</span>
                                        )}
                                    </div>
                                    <hr />
                                    <div class="col-12">
                                        <label for="inputAddress5" class="form-label">Entreprise <span className="text-danger">*</span></label>
                                        <input type="text" class="form-control" id="inputAddres5s" name="entreprise"  onChange={handleChange} value={marchanData.entreprise}/>
                                        {errors && errors.entreprise && (
                                            <span className="text-danger">{errors.entreprise[0]}</span>
                                        )}
                                    </div>
                                        <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Address</label>
                                            <input type="text" class="form-control"  name="adresse"  onChange={handleChange} value={marchanData.adresse}/>
                                        </div>
                                        <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Contact</label>
                                            <input type="text" class="form-control"  name="contactPro"  onChange={handleChange} value={marchanData.contactPro}/>
                                        </div>
                             
                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">N° Registre</label>
                                            <input type="text" class="form-control"  name="registre" onChange={handleChange} value={marchanData.registe}/>

                                        </div>
                                 

                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">Secret Key iMONEY </label>
                                            <input type="text" class="form-control"  name="secretKey_imoney"  onChange={handleChange} value={marchanData.secretKey_imoney}/>

                                        </div>
                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">APIKEY IMONEY</label>
                                            <input type="text" class="form-control"  name="apikey_imoney" onChange={handleChange} value={marchanData.apikey_imoney}/>

                                        </div>
                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">SITE ID IMONEY</label>
                                            <input type="text" class="form-control"  name="siteId_imoney" onChange={handleChange} value={marchanData.siteId_imoney}/>
                                        </div>
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-success">Modifier</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
                    </div> */}

<div class="modal fade" id="basicModaledit" tabindex="-1"  aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title">Modifier {error && <span className="text-danger">{error}</span>}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form class="row g-1" onSubmit={submitFormEdit}> 
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Raison sociale<span className="text-danger">*</span></label>
                                        <input type="text" class="form-control"  name="raison_social" onChange={handleChangeEdit} value={marchanDataEdit.raison_social}/>
                                        {errors && errors.raison_social && (
                                            <span className="text-danger">{errors.raison_social[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputName5" class="form-label">Adresse e-mail </label>
                                        <input type="email" class="form-control"  name="email" onChange={handleChangeEdit} value={marchanDataEdit.email}/>
                                        {errors && errors.email && (
                                            <span className="text-danger">{errors.email[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Asresse </label>
                                        <input type="text" class="form-control"  name="adresse" onChange={handleChangeEdit} value={marchanDataEdit.adresse}/>
                                        {errors && errors.adresse && (
                                            <span className="text-danger">{errors.adresse[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail5" class="form-label">Téléphone </label>
                                        <input type="text" class="form-control"  name="tel" onChange={handleChangeEdit} value={marchanDataEdit.tel}/>
                                        {errors && errors.tel && (
                                            <span className="text-danger">{errors.tel[0]}</span>
                                        )}
                                    </div>
                                    <div class="col-md-4">
                                            <label for="inputState" class="form-label">Pays <span className="text-danger">*</span></label>
                                            <select class="form-select" name="countrie_id" onChange={handleChangeEdit} value={marchanDataEdit.countrie_id}>
                                                <option value="">...</option>
                                                {countrieList?.map((countrie,index)=>
                                                        <option value={countrie.id}>{countrie.libelle}</option>
                                                    )}
                                            </select>
                                        {errors && errors.countrie_id && (
                                            <span className="text-danger">{errors.countrie_id[0]}</span>
                                        )}
                                    </div>
                                    <hr />
                                    <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Pass Api V2 {!seeCheck_Pass && <i class="ri-eye-line p-2 border" onClick={()=>setSeeCheck_Pass(true)}></i>} {seeCheck_Pass && <i class="ri-eye-off-line p-2 border"  onClick={()=>setSeeCheck_Pass(false)}></i>}</label>
                                            <input type={!seeCheck_Pass ? "password" : "text"} class="form-control"  name="check_passe"  onChange={handleChangeEdit} value={marchanDataEdit.check_passe }/>
                                        </div> 

                                    <div class="col-6">
                                        <label for="inputAddress2" class="form-label">Callback Token  </label>
                                        <input type="text" class="form-control"  name="callback_token"  onChange={handleChangeEdit} value={marchanDataEdit.callback_token}/>
                                    </div> 

                                    <div class="col-6">
                                        <label for="inputAddress5" class="form-label">N° Registre de commerce </label>
                                        <input type="text" class="form-control"  name="num_register"  onChange={handleChangeEdit} value={marchanDataEdit.num_register}/>
                                        {errors && errors.num_register && (
                                            <span className="text-danger">{errors.num_register[0]}</span>
                                        )}
                                    </div>

                                    <div class="col-6">
                                        <label for="inputAddress5" class="form-label">Pourcentage (%)</label>
                                        <input type="text" class="form-control"  name="pourcent"  onChange={handleChangeEdit} value={marchanDataEdit.pourcent} placeholder="Ex: 2.50"/>
                                        {errors && errors.pourcent && (
                                            <span className="text-danger">{errors.pourcent[0]}</span>
                                        )}
                                    </div>
                                        {/* <div class="col-6">
                                            <label for="inputAddress2" class="form-label">Address</label>
                                            <input type="text" class="form-control"  name="adresse"  onChange={handleChange} value={marchanData.adresse}/>
                                        </div>*/}
                                        
                                        {/* <div class="col-md-6">
                                            <label for="inputCity" class="form-label">Email professionnel</label>
                                            <input type="text" class="form-control"  name="professionel_mail" onChange={handleChange} value={marchanData.professionel_mail}/>

                                        </div> */}
                                        <div class="col-md-6">
                                            <label for="secteur_activite" class="form-label">Secteur Activité</label>
                                            <input type="text" class="form-control"  name="secteur_activite" onChange={handleChangeEdit} value={marchanDataEdit.secteur_activite}/>

                                        </div>
                                        {/* <div class="col-md-4">
                                            <label for="inputState" class="form-label">Secteur d'activité</label>
                                            <select id="inputState" class="form-select" name="secteur" onChange={handleChange} value={marchanData.secteur}>
                                                <option>Choose...</option>
                                                <option>...</option>
                                            </select>
                                        </div> */}

                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">Secret Key iMONEY  <span className="text-danger">*</span></label>
                                            <input type="text" class="form-control"  name="secretKey_imoney"  onChange={handleChangeEdit} value={marchanDataEdit.secretKey_imoney}/>

                                        </div>
                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">APIKEY IMONEY <span className="text-danger">*</span></label>
                                            <input type="text" class="form-control"  name="apikey_imoney" onChange={handleChangeEdit} value={marchanDataEdit.apikey_imoney}/>

                                        </div>
                                        <div class="col-md-6">
                                            <label for="inputCity" class="form-label">SITE ID IMONEY <span className="text-danger">*</span></label>
                                            <input type="text" class="form-control"  name="siteId_imoney" onChange={handleChangeEdit} value={marchanDataEdit.siteId_imoney}/>
                                        </div>
                                        <div class="modal-footer mt-3">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                        {loadingform && 
                                        <button class="btn btn-primary" type="button" disabled="">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        En chargement...
                                      </button>}
                                        {!loadingform && <button type="submit" class="btn btn-primary">Valider</button>}
                                        
                                        </div>
                                </form>
                            </div>
                           
                        </div>
                        </div>
                    </div>
            </ContentPrincipal>
        </>
    )
}

export default MarchandsPage;