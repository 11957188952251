import Select from "react-select";
import ContentPrincipal from "../Content";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import BaseUrl from "../BaseUrl";
import Swal from "sweetalert2";
import { UserContext } from "../auth/Usercheck";
import can from "../auth/CanUser";
import { useNavigate } from "react-router-dom";

const url = BaseUrl();
function ControleRolesPage(){
    const [permissions,setPermissions] = useState([]);
    const [roles,setRoles] = useState([]);
    const [tabCheck,setTabCheck] = useState([]);
    const [selectUser,setSelectUser] = useState(null);
    const [selectOptionUser,setSelectOptionUser] = useState(null);
    const [loading,setLoading] = useState(false);
    const {user, setUsers} = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(()=>{
        if(!can(user,'voir-page-control-acces')){
            navigate('/');
        }
        fetchDataPermissions();
    },[user]);

    useEffect(()=>{
        fetchDataUsers();
    },[tabCheck]);

    const fetchDataUsers=async()=>{

        try {
            axios.get(url+'/role-list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{

                if(resp.status == 200)
                {
                    setRoles(resp.data.data);
                }
            })
        } catch (error) {
            
        }
    }

    const fetchDataPermissions=async()=>{
        setLoading(true);
        try {
          await  axios.get(url+'/permission-list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                if(resp.status == 200)
                {
                    setPermissions(resp.data.data);
                }
                setLoading(false);
            })
        } catch (error) {
            setLoading(false);
        }
    }

    const optionsUser = roles?.map((option)=>(({
        value: `${option.id}`,
        label:`${option?.name}`
    })));

    const handleChangeSelectOptionUser=(selectOption)=>{
        setSelectOptionUser(selectOption.value);

        setLoading(true);
        try {
          axios.get(url+'/role-get/'+selectOption.value,{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                // console.log(resp.data);
                setTabCheck(resp.data);
                if(resp.status == 200)
                {
                    fetchDataPermissions();
                }
                setLoading(false);
            })
        } catch (error) {
            setLoading(false);
        }
    }

    const handleChangePermissionCheck=(e)=>{

        if(tabCheck.find((list)=> list === e.target.value)){
            const tab = [...tabCheck];
            const itemIndex = tab.findIndex(item => item === e.target.value);
            tab.splice(itemIndex, 1);

            setTabCheck(tab);
        }else{
            setTabCheck([...tabCheck,e.target.value]);
        }
    }


    const submitFormPermission=()=>{
        if(selectOptionUser !== null && tabCheck.length > 0){
            const _formData = new FormData();
            _formData.append('role_id',selectOptionUser);
            _formData.append('permissions',tabCheck);

            console.log(_formData);

            try {
                axios.post(url+'/attach-roles',_formData,
               {
                   headers:{
                       'Content-Type':'application/json',
                       "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                       
                   },
                   credentials:'include'
               }
               ).then((resp)=>{
                   // console.log('ici'); 
                   if(resp.data.status == true)
                   {
                       Swal.fire({
                           position: 'top-end',
                           icon: 'success',
                           title: resp.data.message,
                           showConfirmButton: false,
                           timer: 3000,
                           toast:true,
                           position:'top-right',
                           timerProgressBar:true
                         });
                         window.location.reload();
                     }else{
                       Swal.fire({
                           position: 'top-end',
                           icon: 'error',
                           title:  resp.data.message,
                           showConfirmButton: false,
                           timer: 3000,
                           toast:true,
                           position:'top-right',
                           timerProgressBar:true
                         });
                   }

               }).catch((error)=>{   
                //    setError(error.response.data.message);
                //    setErrors(error.response.data.error);
               })
           } catch (error) {
               console.log(error);
           } 
        }else{
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title:  "Désolé aucune permission n'a été selectionnée",
                showConfirmButton: false,
                timer: 3000,
                toast:true,
                position:'top-right',
                timerProgressBar:true
              });
        }
    }


    return (
        <>
            <ContentPrincipal navactive={"controlroles"} ulShownav={"parametrage"}>
            <div class="pagetitle">
                <div className="text-center mb-5">
                    <Select placeholder="Selectionner un role" options={optionsUser} onChange={handleChangeSelectOptionUser}/>
                </div>

            </div>

            <section class="section">

            {loading && 
                <div className="text-center">
                    
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                
                </div>
            }

            {!loading && 
            <div class="iconslist">
                {permissions.map((permission,index)=>
                    <div class="icon" key={index}>
                        <input type="checkbox" className="form-check-input" name="permissions"  value={permission.libelle} onChange={handleChangePermissionCheck} checked={tabCheck.find((list)=> list === permission.libelle) ? "checked" : ""}/>
                        <div class="label">{permission.libelle}</div>
                    </div>
                )}     
            </div>
            }

            <div className="text-center mt-5">
                    <button type="button" class="btn btn-primary" onClick={submitFormPermission}>Enregistrer les permissions</button>
            </div>
            </section>
            </ContentPrincipal>
        </>
    )
}

export default ControleRolesPage;