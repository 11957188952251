import { useContext, useEffect, useState } from "react";
import ContentPrincipal from "../Content";
import Swal from "sweetalert2";
import axios from "axios";
import { UserContext } from "../auth/Usercheck";
import { useNavigate } from "react-router-dom";
import BaseUrl from "../BaseUrl";
import can from "../auth/CanUser";


const url = BaseUrl();
function RolePage(){
    const {user, setUsers} = useContext(UserContext);
    const navigate = useNavigate();

    const [errors,setErrors] = useState({});
    const [loadingform,setLoadingform] = useState(false);
    const [loading, setLoading] = useState();
    const [error,setError] = useState();
    const [role,setRoleEdit] = useState();
    const [roleData,setRoleData] = useState({
        "name":""
    });
    const [roles,setRoles] = useState([]);

    useEffect(()=>{
        if(!can(user,'voir-page-permission')){
            navigate('/');
        }
        fetchDataItems();
    },[]);

    const fetchDataItems=async()=>{
        setLoading(true);
        try {
            axios.get(url+'/role-list',{
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
            }).then((resp)=>{
                setLoading(false);
                if(resp.status == 200)
                {
                    setRoles(resp.data.data);
                }
            })
        } catch (error) {
            
        }
    }

    const openModaladd =()=>{
        setRoleData({
            "name":"",
        });
        window.$('#basicModal').modal('show');
    }

    const handleChange = (e)=>{
        setRoleData({
            ...roleData,
            [e.target.name]:e.target.value
        });
    }

    const submitForm= (e)=>{
        e.preventDefault();
  
        const _formData = new FormData();
        _formData.append('name',roleData.name);

        setLoadingform(true);
        try {
             axios.post(url+'/role-store',_formData,
            {
                headers:{
                    'Content-Type':'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                    
                },
                credentials:'include'
            }
            ).then((resp)=>{
                // console.log('ici');
                setLoadingform(false);
                if(resp.status == 200)
                {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: resp.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                      fetchDataItems();
                      setRoleData({
                        "name":"",
                    })
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title:  resp.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                }
                window.$('#basicModal').modal('hide');
            }).catch((error)=>{
                setLoadingform(false);
                setError(error.response.data.message);
                setErrors(error.response.data.error);
            })
        } catch (error) {
            console.log(error);
        } 




    }

    const submitFormEdit = (e)=>{
        e.preventDefault();

        const _formData = new FormData();
        _formData.append('name',roleData.name);
        _formData.append('id',role.id);

        // console.log(_formData);

        try {
            axios.post(url+'/role-update',_formData,
           {
               headers:{
                   'Content-Type':'application/json',
                   "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                   
               },
               credentials:'include'
           }
           ).then((resp)=>{
               // console.log('ici');
               setLoadingform(false);
               if(resp.status == 200)
               {
                   Swal.fire({
                       position: 'top-end',
                       icon: 'success',
                       title: resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }else{
                   Swal.fire({
                       position: 'top-end',
                       icon: 'error',
                       title:  resp.data.message,
                       showConfirmButton: false,
                       timer: 3000,
                       toast:true,
                       position:'top-right',
                       timerProgressBar:true
                     });
               }
               fetchDataItems();
               window.$('#basicModal').modal('hide');
           }).catch((error)=>{
               setLoadingform(false);
               setError(error.response.data.message);
               setErrors(error.response.data.error);
           })
       } catch (error) {
           console.log(error);
       } 
    
    }

    const openModalEdit = (item)=>{
        setRoleEdit(item);

        setRoleData({
            "name":item.name

        })
        window.$('#basicModal').modal('show');
    }

    const deleteItems=(id)=>{
        Swal.fire({
            title: 'ATTENTION !',
            text: 'Êtes-vous sûre de vouloir supprimer cet élement.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonText:"NON",
            confirmButtonText: 'OUI',
            cancelButtonColor:"red"
          }).then((result) => {
            if (result.isConfirmed) {
                try {
                    axios.get(url+'/role-delete/?id='+id,
                        {
                            headers:{
                                'Content-Type':'application/json',
                                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                                
                            },
                        }
                    ).then((resp)=>{
                        if(resp.status == 200)
                        {
                            Swal.fire(
                                'Supprimé',
                                resp.data.message,
                                'success'
                              )
                        }else{
                            Swal.fire(
                                'Attention',
                                resp.data.message,
                                'error'
                              )
                        }
                        fetchDataItems();
                    })
                } catch (error) {
                    console.log(error);
                }

            
            }
          }); 
    }

    return (
        <>
    <ContentPrincipal ulShownav={"parametrage"} navactive={"roles"}>
        <div class="pagetitle">
            <h1>Page des Rôles <button type="button" class="btn btn-primary btn-sm float-end" onClick={openModaladd}>Ajouter un rôle</button></h1>
        </div>

        <section class="section">
        <div class="row">
            <div class="col-lg-12">

            <div class="card">
                <div class="card-body">
                <h5 class="card-title">Liste des Rôles</h5>

                
                <table class="table datatable">
                    <thead>
                    <tr>
                    
                        <th>Libelle</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {roles?.map((item,index)=>
                    <tr>
                        <td>{item.name}</td>
                        <td>
                        <button className="btn btn-primary btn-sm" onClick={()=>openModalEdit(item)}><i class="ri-ball-pen-fill"></i></button>
                        <button className="btn btn-danger btn-sm mx-1"   onClick={()=>deleteItems(item.id)} ><i class="bi bi-trash-fill"></i></button>
                        </td>
                    </tr>
                    )}
                    
                    </tbody>
                </table>
            

                </div>
            </div>

            </div>
        </div>
        </section>


        <div class="modal fade" id="basicModal" tabindex="-1"  aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{role==null ? 'Page d\'ajout' : 'Modification'}{error && <span className="text-danger">{error}</span>}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form class="row g-1" onSubmit={role==null ? submitForm : submitFormEdit}> 
                            <div class="col-md-12">
                                <label for="inputName5" class="form-label">Libelle <span className="text-danger">*</span></label>
                                <input type="text" class="form-control" id="inputName5" name="name" onChange={handleChange} value={roleData.name}/>
                                {errors && errors.name && (
                                    <span className="text-danger">{errors.name[0]}</span>
                                )}
                            </div>
                        
                            
                            
                            <div class="modal-footer mt-3">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                    {loadingform && 
                                    <button class="btn btn-primary" type="button" disabled="">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    En chargement...
                                    </button>}
                                {!loadingform && <button type="submit" class="btn btn-primary">Enregistrer</button>}
                                
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>


    </ContentPrincipal>
        </>
    )
}
export default RolePage;